import * as amplitude from "@amplitude/analytics-browser";
import debounce from "lodash.debounce";
import { v4 as uuidv4 } from "uuid";

const isProduction = process.env.NODE_ENV === "production";

export const initAmplitude = () => {
  if (isProduction && process.env.REACT_APP_AMPLITUDE_API_KEY) {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
      defaultTracking: false,
    });
  }
};

export const setAmplitudeUserDevice = (id: string) => {
  const STORAGE_KEY = "amplitude-device-id";

  const getOrGenerateUUID = () => {
    if (id) return id;

    let uuid = localStorage.getItem(STORAGE_KEY);
    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem(STORAGE_KEY, uuid);
    }
    return uuid;
  };

  const uuid = getOrGenerateUUID();

  if (isProduction) {
    amplitude.setDeviceId(uuid);
  } else {
    console.log("Amplitude Device ID:", uuid);
  }
};

export const setAmplitudeUserId = (userId: string) => {
  if (isProduction) {
    amplitude.setUserId(userId);
  } else {
    console.log("Amplitude User ID", userId);
  }
};

export const sendAmplitudeData = (
  eventType: string,
  eventProperties?: Record<string, any>
) => {
  const props = {
    ...eventProperties,
    page: window.location.origin + window.location.pathname,
  };

  if (isProduction) {
    amplitude.logEvent(eventType, props);
  } else {
    console.log("Amplitude Event", eventType, props);
  }
};

export const debouncedAmplitudeEvent = debounce(
  (eventType: string, eventProperties?: Record<string, any>) => {
    sendAmplitudeData(eventType, eventProperties);
  },
  500
);
