import { useAppDispatch, useAppSelector } from "@/store/app/hooks";
import {
  activeTemplateSelector,
  templatesSelector,
} from "@/store/features/email/emailSelectors";
import { setActiveTemplate } from "@/store/features/email/emailSlice";

import { ReactComponent as CheckIcon } from "@/assets/icons/check.svg";
import { ReactComponent as EditIcon } from "@/assets/icons/edit.svg";
import { useEffect } from "react";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";

export const TemplateSelector = () => {
  const dispatch = useAppDispatch();
  const templates = useAppSelector(templatesSelector);
  const activeTemplateId = useAppSelector(activeTemplateSelector);

  useEffect(() => {
    if (!activeTemplateId && templates)
      dispatch(setActiveTemplate(templates[0]?.id));
  }, [activeTemplateId, dispatch, templates]);

  const handleTemplateClick = (templateId: number) => {
    dispatch(setActiveTemplate(templateId));
    sendAmplitudeData(AMPLITUDE_EVENTS.popupTemplateSwitched);
  };

  const handleNewTemplateClick = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.popupNewTemplateClicked);
  };

  return (
    <div className="border-r-1 border-gray-200 h-full w-[150px] flex flex-col justify-between p-3 shrink-0">
      <div className="flex flex-col gap-3">
        <h5 className="text-sm text-gray-500">Choose template</h5>
        <div className="w-full">
          {templates?.map((template) => (
            <div
              key={template.id}
              onClick={() => handleTemplateClick(template.id)}
              className="w-full min-h-8 text-wrap break-all text-sm font-medium flex justify-between items-center cursor-pointer hover:text-brand-blue-700"
            >
              {template.name}
              {activeTemplateId === template.id && (
                <CheckIcon className="shrink-0" />
              )}
            </div>
          ))}
        </div>
      </div>
      <a
        className="border-none flex justify-between items-center text-brand-blue-700 text-sm font-medium"
        href="https://app.loadconnect.io/emailTemplate"
        target="_blank"
        rel="noreferrer"
        onClick={handleNewTemplateClick}
      >
        New template
        <EditIcon />
      </a>
    </div>
  );
};
