import { Chip, ChipProps } from "@nextui-org/react";
import { FC, ReactNode } from "react";

interface IProps extends ChipProps {
  children?: ReactNode;
  icon?: ReactNode;
  color?:
    | "primary"
    | "default"
    | "secondary"
    | "success"
    | "warning"
    | "danger";
}

export const ContactChip: FC<IProps> = ({
  children,
  icon,
  color = "primary",
  ...rest
}) => {
  return (
    <Chip
      {...rest}
      radius="sm"
      variant="flat"
      color={color}
      classNames={{
        content: "font-medium pl-0",
        base: "cursor-pointer hover:border-bottom-1",
      }}
      endContent={icon}
    >
      {children}
    </Chip>
  );
};
