import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILoadDataV2 } from "@/types/common";

export interface ILoadInfoState {
  data?: ILoadDataV2;
  includeDeadHead: boolean;
}

const initialState: ILoadInfoState = {
  data: undefined,
  includeDeadHead: false,
};

export const loadInfoSlice = createSlice({
  name: "loadInfo",
  initialState,
  reducers: {
    setLoadData: (state, action: PayloadAction<ILoadDataV2>) => {
      state.data = action.payload;
      state.includeDeadHead = !!action.payload.truck_location;
    },
    setIncludeDeadHead: (state, action: PayloadAction<boolean>) => {
      state.includeDeadHead = action.payload;
    },
    resetLoadInfoState: () => initialState,
  },
});

export const { setLoadData, setIncludeDeadHead, resetLoadInfoState } =
  loadInfoSlice.actions;

export default loadInfoSlice.reducer;
