export const getDateDifference = (inputDate: string = "") => {
  const date = new Date(inputDate);
  const currentDate = new Date();
  let totalMonthsDifference =
    (currentDate.getFullYear() - date.getFullYear()) * 12;
  totalMonthsDifference += currentDate.getMonth() - date.getMonth();

  if (currentDate.getDate() < date.getDate()) {
    totalMonthsDifference--;
  }

  const yearsDifference = Math.floor(totalMonthsDifference / 12);
  const monthsDifference = totalMonthsDifference % 12;

  return `${yearsDifference} ${
    yearsDifference === 1 ? "year" : "years"
  } ${monthsDifference} month`;
};
