import classNames from "classnames";
import { FC, ReactNode } from "react";

interface IProps {
  icon?: ReactNode;
  header: string;
  danger?: boolean;
  children?: ReactNode;
}

export const DataGroup: FC<IProps> = ({
  icon,
  header,
  danger = false,
  children,
}) => {
  const cn = classNames(
    "p-3",
    "rounded-lg",
    "flex",
    "flex-col",
    "gap-3",
    danger ? "bg-error-50" : "bg-success-50",
    danger ? "text-error-500" : "text-success-600"
  );
  return (
    <div className={cn}>
      <div className="flex gap-2 items-center text-sm">
        {icon}
        {header}
      </div>
      <div className="flex flex-col gap-1">{children}</div>
    </div>
  );
};
