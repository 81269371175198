import { TRootState } from "@/store/app/store";
import { resetAccountState } from "@/store/features/account/accountSlice";
import { resetCarrierReportState } from "@/store/features/carrier-report/carrierReportSlice";
import { resetEmailState } from "@/store/features/email/emailSlice";
import { resetLoadInfoState } from "@/store/features/load-info/loadInfoSlice";
import { resetTabsState } from "@/store/features/tabs/tabsSlice";
import { resetTripInfoState } from "@/store/features/trip-info/tripInfoSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";

export const resetStore = (dispatch: ThunkDispatch<TRootState, any, any>) => {
  dispatch(resetAccountState());
  dispatch(resetEmailState());
  dispatch(resetLoadInfoState());
  dispatch(resetTripInfoState());
  dispatch(resetTabsState());
  dispatch(resetCarrierReportState());
};
