import { useAppSelector } from "@/store/app/hooks";
import { tripDataSelector } from "@/store/features/trip-info/tripInfoSelectors";
import { ReactComponent as DistanceIcon } from "@/assets/icons/distance.svg";
import { ReactComponent as DurationIcon } from "@/assets/icons/duration.svg";
import { ReactComponent as TollsIcon } from "@/assets/icons/tolls.svg";
import { ReactComponent as DieselIcon } from "@/assets/icons/diesel.svg";
import { MetricCard } from "@/components/metric-card";
import { IncludeDeadHead } from "./IncludeDeadHead";
import { OpenGoogleMaps } from "./OpenGoogleMaps";
import { Map } from "./Map";

export const MapView = () => {
  const tripData = useAppSelector(tripDataSelector);

  const { distance, duration, tollCost, fuelPrice } = tripData || {};

  return (
    <div className="w-full h-full bg-text-primary relative">
      <OpenGoogleMaps />
      <Map />
      <div className="absolute bottom-0 left-0 right-0 p-3 df">
        <div className="grid grid-cols-2 gap-2">
          <IncludeDeadHead />
          <MetricCard
            icon={<DistanceIcon />}
            label="Distance"
            value={distance}
          />
          <MetricCard
            icon={<DurationIcon />}
            label="Duration"
            value={duration}
          />
          <MetricCard
            icon={<TollsIcon />}
            label="Tolls"
            value={tollCost && `$ ${tollCost}`}
          />
          <MetricCard
            icon={<DieselIcon />}
            label="Diesel Price/gl"
            value={fuelPrice && `$ ${fuelPrice?.diesel}`}
          />
        </div>
      </div>
    </div>
  );
};
