import { FC, useEffect } from "react";
import { Layout } from "@/components/layout";
import { Header } from "@/components/header";
import { MapView } from "@/components/map-view";
import { CalculatorView } from "@/components/calculator-view";
import { MainView } from "@/components/main-view";
import { Brand } from "@/components/brand";
import { useAppDispatch } from "@/store/app/hooks";
import { setLoadData } from "@/store/features/load-info/loadInfoSlice";
import {
  setAccountData,
  setTokens,
} from "@/store/features/account/accountSlice";
import {
  setActiveTemplate,
  setEmailData,
  setRecieverEmail,
} from "@/store/features/email/emailSlice";
import { IMessage, ITokens } from "@/types/common";
import {
  getAccountsAsync,
  getTemplatesAsync,
} from "./store/features/email/emailAsyncThunks";
import { setActiveTab } from "./store/features/tabs/tabsSlice";
import { resetStore } from "./utils/resetStore";
import { getUserInfoAsync } from "./store/features/account/accountAsyncThunks";
import { setAmplitudeUserDevice } from "./analytics";

const App: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const receiveMessage = async ({ data }: { data: IMessage | string }) => {
      if (typeof data === "object" && data?.data?.origin) {
        resetStore(dispatch);
        const tokens: ITokens = {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        };
        dispatch(setTokens(tokens));
        await dispatch(getAccountsAsync(tokens));
        await dispatch(getTemplatesAsync(tokens));
        await dispatch(getUserInfoAsync(tokens));
        dispatch(setLoadData(data.data));
        dispatch(setAccountData(data.activeAccount));
        dispatch(setActiveTemplate(data.activeTemplateId));
        dispatch(setEmailData(data.data.email_data));

        setAmplitudeUserDevice(data.deviceId);

        if (data.data.email_data.email) {
          dispatch(setRecieverEmail(data.data.email_data.email));
        }

        if (data.data.email_data.email) {
          dispatch(setActiveTab("email"));
        } else {
          dispatch(setActiveTab("loadInfo"));
        }
      }
    };

    window.addEventListener("message", receiveMessage, false);
    return () => window.removeEventListener("message", receiveMessage, false);
  }, [dispatch]);

  return (
    <Layout>
      <Header />
      <MapView />
      <CalculatorView />
      <Brand />
      <MainView />
    </Layout>
  );
};

export default App;
