import { FC, useEffect, useRef } from "react";
import {
  getFuelPriceApi,
  getTollApi,
  updatePricePrecision,
} from "@/modules/server";
import { useAppDispatch, useAppSelector } from "@/store/app/hooks";
import {
  includeDeadHeadSelector,
  loadDataSelector,
} from "@/store/features/load-info/loadInfoSelectors";
import {
  resetTollCost,
  setDistance,
  setDistanceDifference,
  setDuration,
  setFuelPrice,
  setLoading,
  setTollCost,
} from "@/store/features/trip-info/tripInfoSlice";
import { getDistance, getDuration, getRequestData } from "@/utils/mapUtils";
import { IRouteRequest } from "@/types/common";
import { distanceDifferenceSelector } from "@/store/features/trip-info/tripInfoSelectors";
import TollIcon from "@/assets/icons/toll.png";

const center = {
  lat: 37.839333,
  lng: -84.27002,
};

export const Map: FC = () => {
  const dispatch = useAppDispatch();

  const loadData = useAppSelector(loadDataSelector);
  const includeDeadHead = useAppSelector(includeDeadHeadSelector);
  const distanceDifference = useAppSelector(distanceDifferenceSelector);

  const mapRef = useRef<any>(null);
  const directionsService = useRef<any>(null);
  const directionsRenderer = useRef<any>(null);

  const initializeMap = () => {
    const mapState = {
      center,
      zoom: 5,
      options: {
        gestureHandling: "greedy",
        disableDefaultUI: true,
      },
    };
    const mapContainer = document.getElementById("map");
    if (!mapContainer) return;
    const map = new google.maps.Map(mapContainer, mapState);

    mapRef.current = map;
    directionsService.current = new google.maps.DirectionsService();
    directionsRenderer.current = new google.maps.DirectionsRenderer();
  };

  useEffect(() => {
    initializeMap();
  }, []);

  const getFuelPrice = async (
    startLocation: any,
    duration: string,
    distance: string,
    tollCost: number
  ) => {
    dispatch(setLoading(true));

    const lat = startLocation.lat();
    const lon = startLocation.lng();
    getFuelPriceApi(lat, lon)
      .then((fuelPrice) => {
        updatePricePrecision(fuelPrice);
        dispatch(setFuelPrice(fuelPrice));
        dispatch(setTollCost(tollCost.toString()));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const getToll = async (
    polyline: string,
    duration: string,
    distance: string
  ): Promise<number> => {
    try {
      dispatch(setLoading(true));
      const data = await getTollApi(polyline);

      if (!data.costs.minimumTollCost) {
        dispatch(resetTollCost());
        return 0;
      } else {
        dispatch(setTollCost(data.costs.minimumTollCost));
      }

      data.tolls.forEach((toll: any) => {
        new google.maps.Marker({
          position: { lat: toll.lat, lng: toll.lng },
          map: mapRef.current,
          icon: {
            url: TollIcon,
            scaledSize: new google.maps.Size(30, 30),
          },
        });
      });

      dispatch(setLoading(false));
      return data.costs.minimumTollCost;
    } catch (e) {
      dispatch(resetTollCost());
      dispatch(setLoading(false));

      return 0;
    }
  };

  const setMap = (result: any) => {
    const distance = getDistance(result?.routes[0]?.legs) as string;
    const duration = getDuration(result?.routes[0]?.legs) as string;

    dispatch(setDistance(distance));
    if (includeDeadHead && !distanceDifference)
      dispatch(setDistanceDifference(result?.routes[0]?.legs[0].distance.text));
    dispatch(setDuration(duration));

    getToll(result?.routes[0].overview_polyline, duration, distance).then(
      (tollCost) => {
        getFuelPrice(
          result?.routes[0]?.legs[0]?.start_location,
          duration,
          distance,
          tollCost
        );
      }
    );

    directionsRenderer.current.setMap(mapRef.current);
    directionsRenderer.current.setDirections(result);
  };

  const requestRouteData = (request: IRouteRequest) => {
    directionsService.current.route(request, (result: any, status: any) => {
      if (status === google.maps.DirectionsStatus.OK) {
        setMap(result);
      }
    });
  };

  useEffect(() => {
    if (
      loadData?.origin &&
      loadData.origin !== "" &&
      loadData.destination !== ""
    ) {
      const request = getRequestData(loadData, includeDeadHead);
      requestRouteData(request);
    }
    // eslint-disable-next-line
  }, [loadData?.origin, loadData?.destination, includeDeadHead]);

  return <div id="map" className="w-full h-full relative"></div>;
};
