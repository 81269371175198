import { FC, ReactNode } from "react";

interface IProps {
  label?: string;
  value?: ReactNode;
  subValue?: string;
}

export const Data: FC<IProps> = ({ label, value, subValue }) => {
  if (!value) return null;

  return (
    <div className="flex justify-between items gap-2">
      <span className="text-gray-500 text-xs shrink-0">{label}</span>
      <div className="flex flex-col items-end">
        <span className="text-gray-950 text-xs font-medium text-end">
          {value}
        </span>
        <span className="text-gray-600 text-xs text-end">{subValue}</span>
      </div>
    </div>
  );
};
