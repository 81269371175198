import { ILoadDataV2, IRouteRequest } from "@/types/common";
import geoData from "@/assets/data/usa-geo.json";

export const getDistance = (legs: any[], raw: boolean = false) => {
  const meters = legs.reduce((acc, leg) => acc + leg.distance.value, 0);
  const miles = Math.round(meters * 0.000621371);

  return raw ? miles : miles + " mi";
};

export const getDuration = (legs: any[]) => {
  let seconds = legs.reduce((acc, leg) => acc + leg.duration.value, 0);

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours} h ${minutes} m`;
};

export const getFullLocation = (location: string) => {
  if (location.length < 3) {
    return (
      geoData.features.find((feature) => feature.properties.id === location)
        ?.properties.name || ""
    );
  }

  return location;
};

export const getRequestData = (
  loadData: ILoadDataV2,
  includeDeadHead: boolean
): IRouteRequest =>
  includeDeadHead
    ? {
        origin: getFullLocation(loadData.truck_location),
        destination: getFullLocation(loadData.destination),
        waypoints: includeDeadHead
          ? [{ location: getFullLocation(loadData.origin) }]
          : [],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        avoidTolls: false,
      }
    : {
        origin: getFullLocation(loadData.origin),
        destination: getFullLocation(loadData.destination),
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        avoidTolls: false,
      };
