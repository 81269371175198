import { DataGroup } from "./DataGroup";
import { Data } from "./Data";
import { useAppSelector } from "@/store/app/hooks";
import { carrierReportDataSelector } from "@/store/features/carrier-report/carrierReportSelectors";

import { ReactComponent as InsuranceIcon } from "@/assets/icons/certificate.svg";
import { ReactComponent as LoadingIcon } from "@/assets/icons/loading.svg";
import { getDateDifference } from "@/utils/getDateDifference";

export const AuthorityStatuses = () => {
  const carrierReportData = useAppSelector(carrierReportDataSelector);
  const { authority, entity_type } = carrierReportData || {};
  const {
    allowedToOperate,
    bipdInsuranceOnFile,
    cargoInsuranceRequired,
    brokerAuthorityStatus,
    safetyReviewDate,
  } = authority || {};

  const isTrue = (value?: boolean) => (value ? "YES" : "NO");
  const isActive = (value?: boolean) => (value ? "Active" : "Inactive");

  const isCarrierAuthority = allowedToOperate === "Y";
  const isBrokerAuthority = entity_type?.includes("BROKER");

  return (
    <div className="grid grid-cols-3 gap-2">
      <DataGroup icon={<InsuranceIcon />} header="Insurance">
        <Data label="BIPD" value={`$ ${Number(bipdInsuranceOnFile) * 1000}`} />
        <Data label="Cargo" value={isTrue(cargoInsuranceRequired === "Y")} />
        <Data label="BOND" value={isTrue(brokerAuthorityStatus === "A")} />
      </DataGroup>
      <DataGroup
        header="Carrier authority"
        icon={<LoadingIcon />}
        danger={!isCarrierAuthority}
      >
        <Data label="Status" value={isActive(isCarrierAuthority)} />
        <Data
          label="Since"
          value={safetyReviewDate}
          subValue={getDateDifference(safetyReviewDate)}
        />
      </DataGroup>
      <DataGroup
        header="Broker authority"
        icon={<LoadingIcon />}
        danger={!isBrokerAuthority}
      >
        <Data label="Status" value={isActive(isBrokerAuthority)} />
        <Data
          label="Since"
          value={safetyReviewDate}
          subValue={getDateDifference(safetyReviewDate)}
        />
      </DataGroup>
    </div>
  );
};
