import { createSlice } from "@reduxjs/toolkit";
import { getCarrierReportAsync } from "./carrierReportAsyncThunks";
import { ICarrierReportData } from "@/types/carrierReport";

export interface ICarrierReportState {
  loading: boolean;
  data: ICarrierReportData | null;
}

const initialState: ICarrierReportState = {
  loading: false,
  data: null,
};

export const carrierReportSlice = createSlice({
  name: "carrierReport",
  initialState,
  reducers: {
    resetCarrierReportState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCarrierReportAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCarrierReportAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getCarrierReportAsync.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { resetCarrierReportState } = carrierReportSlice.actions;

export default carrierReportSlice.reducer;
