import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAccount, ITokens } from "@/types/common";
import { getUserInfoAsync } from "./accountAsyncThunks";
import { setAmplitudeUserDevice, setAmplitudeUserId } from "@/analytics";

export interface IAccountState extends ITokens {
  activeAccount?: IAccount;
  userData: any;
  loading?: boolean;
}

const initialState: IAccountState = {
  loading: false,
  accessToken: "",
  refreshToken: "",
  activeAccount: undefined,
  userData: {},
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccountData: (state, action: PayloadAction<IAccount>) => {
      state.activeAccount = action.payload;
    },
    setTokens: (state, action: PayloadAction<ITokens>) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    resetAccountState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfoAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserInfoAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.userData = action.payload;
      setAmplitudeUserId(action.payload.email);
    });
    builder.addCase(getUserInfoAsync.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setAccountData, setTokens, resetAccountState } =
  accountSlice.actions;

export default accountSlice.reducer;
