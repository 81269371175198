import { Group } from "@/components/group";
import { ContactChip } from "@/components/contact-chip";

import { useAppSelector } from "@/store/app/hooks";
import { carrierReportDataSelector } from "@/store/features/carrier-report/carrierReportSelectors";

import { ReactComponent as TruckIcon } from "@/assets/icons/truck.svg";
import { ReactComponent as CalendarIcon } from "@/assets/icons/calendar.svg";
import { ReactComponent as VerifiedIcon } from "@/assets/icons/verified.svg";

import { Stats } from "./Stats";
import { Data } from "./Data";

export const DBAData = () => {
  const carrierReportData = useAppSelector(carrierReportDataSelector);

  const {
    safety_review_date,
    power_units,
    mcs_150_mileage_year,
    physical_address,
    phone,
    email,
  } = carrierReportData || {};

  return (
    <div className="flex flex-col gap-3">
      <div className="grid grid-cols-2 gap-3">
        <Stats
          label="Started"
          value={safety_review_date}
          icon={<CalendarIcon />}
        />
        <Stats label="Trucks" value={power_units} icon={<TruckIcon />} />
      </div>
      <Group className="h-full">
        <div className="h-full flex flex-col gap-2">
          <Data
            label={`${mcs_150_mileage_year?.year} mileage`}
            value={mcs_150_mileage_year?.mileage}
          />
          <Data label="Business address" value={physical_address} />
          {email && (
            <Data
              label="Email domain"
              value={
                <ContactChip size="sm" color="success" icon={<VerifiedIcon />}>
                  {email}
                </ContactChip>
              }
            />
          )}
          {phone && (
            <Data
              label="Phone domain"
              value={
                <ContactChip size="sm" color="primary">
                  {phone}
                </ContactChip>
              }
            />
          )}
        </div>
      </Group>
    </div>
  );
};
