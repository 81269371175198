import { FC, useRef } from "react";
import { Variable } from "./Variable";
import ReactQuill from "react-quill";
import { useAppSelector } from "@/store/app/hooks";
import { emailDataSelector } from "@/store/features/email/emailSelectors";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { sendAmplitudeData } from "@/analytics";
import debounce from "lodash.debounce";
import "react-quill/dist/quill.snow.css";
import "./index.css";

interface IProps {
  value?: string;
  onChange?: any;
}

export const TextEditor: FC<IProps> = ({ value, onChange }) => {
  const emailData = useAppSelector(emailDataSelector);
  const quillRef = useRef<any>(null);

  const handleClick = (e: string) => {
    const quill = quillRef.current.getEditor();
    const range = quill.selection.savedRange;
    if (range) {
      const position = range.index || 0;
      quill.insertText(position, `{{${e}}} `);
      sendAmplitudeData(AMPLITUDE_EVENTS.popupVariablesClicked);
    }
  };

  const debouncedHandleChange = debounce(
    (value: string) => onChange(value),
    500
  );

  return (
    <div className="w-full h-full flex flex-col gap-3">
      <div className="text-editor">
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={value}
          onChange={debouncedHandleChange}
          className="w-full h-full overflow-auto flex flex-col border-b-1 border-gray-200"
        />
      </div>
      {value && (
        <div className="p-3 flex flex-col gap-2">
          <h3 className="text-sm text-gray-500 font-medium">
            Select variables
          </h3>
          <div className="flex flex-wrap gap-1">
            {Object.keys(emailData).map(
              (key) =>
                // @ts-ignore
                !!emailData[key] && (
                  <Variable
                    key={key}
                    // @ts-ignore
                    label={key}
                    // @ts-ignore
                    onClick={() => handleClick(key)}
                  />
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
};
