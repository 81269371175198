import { FC, ReactNode } from "react";
import classNames from "classnames";

interface IProps {
  label: string;
  value?: ReactNode;
  icon?: ReactNode;
  status?: "success" | "warning" | "danger";
}

const BG_COLORS = {
  success: "success-50",
  warning: "warning-50",
  danger: "error-50",
};

const ICON_COLORS = {
  success: "success-600",
  warning: "warning-500",
  danger: "error-500",
};

export const Stats: FC<IProps> = ({
  label,
  value,
  icon,
  status = "success",
}) => {
  const cn = classNames("flex", "flex-col", "p-3", "gap-1", "rounded-lg", `bg-${BG_COLORS[status]}`);

  return (
    <div className={cn}>
      <h5 className="text-xs text-gray-500">{label}</h5>
      <div className="flex gap-2 items-center">
        <span className={`text-${ICON_COLORS[status]}`}>{icon}</span>
        <p className="text-xs text-gray-950 font-medium">{value ?? "-"}</p>
      </div>
    </div>
  );
};
