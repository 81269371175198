import { useCallback, useEffect, useState } from "react";
import { InputField } from "./InputField";
import { useAppSelector } from "@/store/app/hooks";
import { loadDataSelector } from "@/store/features/load-info/loadInfoSelectors";
import {
  tripDataLoadingSelector,
  tripDataSelector,
} from "@/store/features/trip-info/tripInfoSelectors";
import { Loader } from "../loader";
import { isRateEmpty } from "@/utils/isRateEmpty";
import { debouncedAmplitudeEvent } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";

export const CalculatorView = () => {
  const loadInfo = useAppSelector(loadDataSelector);
  const tripInfo = useAppSelector(tripDataSelector);
  const loading = useAppSelector(tripDataLoadingSelector);

  const [miles, setMiles] = useState(0);
  const [rpm, setRpm] = useState(0);
  const [mpg, setMpg] = useState(6.8);
  const [diesel, setDiesel] = useState(0);
  const [toll, setToll] = useState(0);
  const [rate, setRate] = useState(0);
  const [fuelCost, setFuelCost] = useState(0);

  const setMilesIn = useCallback(
    (val: number) => {
      setMiles(val);

      setRpm(Number((rate / val).toFixed(2)));
      setFuelCost(Number(((val / mpg) * diesel).toFixed(2)));
      debouncedAmplitudeEvent(AMPLITUDE_EVENTS.popupMilesValueChanged);
    },
    [diesel, mpg, rate]
  );

  const setRpmIn = useCallback(
    (val: number) => {
      setRpm(val);
      setRate(Number((val * miles).toFixed(2)));
      setFuelCost(Number(((miles / mpg) * diesel).toFixed(2)));
      debouncedAmplitudeEvent(AMPLITUDE_EVENTS.popupRpmCalculatorValueChanged);
    },
    [diesel, miles, mpg]
  );

  const setMpgIn = useCallback(
    (val: number) => {
      setMpg(val);
      setFuelCost(Number(((miles / val) * diesel).toFixed(2)));
      debouncedAmplitudeEvent(AMPLITUDE_EVENTS.popupMpgValueChanged);
    },
    [diesel, miles]
  );

  const setRateIn = useCallback(
    (val: number) => {
      setRate(val);

      setRpm(Number((val / miles).toFixed(2)));
      setFuelCost(Number(((miles / mpg) * diesel).toFixed(2)));
      debouncedAmplitudeEvent(AMPLITUDE_EVENTS.popupRateValueChanged);
    },
    [diesel, miles, mpg]
  );

  const setFuelCostTollsIn = useCallback((val: number) => {
    setFuelCost(val);
    debouncedAmplitudeEvent(AMPLITUDE_EVENTS.popupFuelCostValueChanged);
  }, []);

  useEffect(() => {
    if (tripInfo?.fuelPrice?.diesel) {
      const die = Number(tripInfo.fuelPrice.diesel);
      setDiesel(die);
      setFuelCost(Number(((miles / mpg) * die).toFixed(2)));
    }
    if (tripInfo?.tollCost) {
      setToll(Number(tripInfo?.tollCost));
    }
  }, [miles, mpg, tripInfo]);

  useEffect(() => {
    if (!loadInfo || !tripInfo) return;
    let milesIn = 0;
    if (tripInfo?.distance) {
      milesIn = Number(tripInfo?.distance.replace(" mi", "").replace(",", ""));
      setMilesIn(milesIn);
    }
    let rateIn = 0;
    if (!isRateEmpty(loadInfo?.rate)) {
      rateIn = Number(loadInfo?.rate.replace("$", "").replace(",", ""));
      setRateIn(rateIn);
    } else {
      setRateIn(0);
    }

    setMilesIn(milesIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadInfo, tripInfo?.distance]);

  if (!tripInfo || !loadInfo) return <div></div>;

  return (
    <div className="relative flex flex-col justify-between bg-brand-blue-700 text-white">
      <div className="h-full flex flex-col gap-2 p-3">
        <h2 className="font-semibold text-xl">Calculator</h2>
        <div className="h-full flex flex-col gap-2">
          <div className="w-full grid grid-cols-3 gap-2">
            <InputField
              label="Miles"
              value={miles.toString()}
              onValueChange={setMilesIn}
              onFocus={() => console.log("focus")}
            />
            <InputField
              label="RPM"
              step={0.5}
              value={rpm.toString()}
              onValueChange={setRpmIn}
            />
            <InputField
              label="MPG"
              step={0.5}
              value={mpg.toString()}
              onValueChange={setMpgIn}
            />
          </div>
          <div className="w-full grid grid-cols-2 gap-2">
            <InputField
              label="Rate"
              value={rate.toString()}
              onValueChange={setRateIn}
              startContent={<span>$</span>}
            />
            <InputField
              label="Fuel Cost"
              value={fuelCost.toString()}
              onValueChange={setFuelCostTollsIn}
              startContent={<span>$</span>}
            />
          </div>
        </div>
      </div>
      <div className="h-[50px] shrink-0 px-4 py-5 flex justify-between items-center bg-brand-700">
        <h2 className="font-normal text-md">Profit</h2>
        <p className="font-semibold text-md">
          ${(rate - fuelCost - toll).toFixed(2)}
        </p>
      </div>
      <Loader dark show={loading} />
    </div>
  );
};
