import {
  ChangeEventHandler,
  FC,
  Key,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { useAppDispatch, useAppSelector } from "@/store/app/hooks";
import {
  accessTokenSelector,
  accountDataSelector,
  refreshTokenSelector,
} from "@/store/features/account/accountSelectors";
import {
  accountsSelector,
  activeTemplateDataSelector,
  emailDataSelector,
  emailStatusSelector,
  loadingEmailSelector,
  recieverEmailSelector,
  senderEmailKeySelector,
} from "@/store/features/email/emailSelectors";

import { StyledButton } from "@/components/styled-button";
import { TemplateSelector } from "./TemplateSelector";
import { AccountSelectField } from "./AccountSelectField";
import { TextEditor } from "./TextEditor";

import { ReactComponent as SendIcon } from "@/assets/icons/send.svg";
import { ReactComponent as CloseIcon } from "@/assets/icons/close.svg";

import { sendEmailAsync } from "@/store/features/email/emailAsyncThunks";
import {
  setActiveTemplateData,
  setRecieverEmail,
  setSenderEmailKey,
} from "@/store/features/email/emailSlice";
import { AccountInputField } from "./AccountInputField";
import { reformatText } from "@/modules/server";
import { EmailSendStatus } from "@/constants/email-send-status";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";

export const EmailView: FC = () => {
  const dispatch = useAppDispatch();

  const accessToken = useAppSelector(accessTokenSelector);
  const refreshToken = useAppSelector(refreshTokenSelector);
  const activeTemplateData = useAppSelector(activeTemplateDataSelector);
  const accounts = useAppSelector(accountsSelector);
  const loading = useAppSelector(loadingEmailSelector);
  const accountData = useAppSelector(accountDataSelector);
  const emailData = useAppSelector(emailDataSelector);
  const emailStatus = useAppSelector(emailStatusSelector);

  const senderEmailKey = useAppSelector(senderEmailKeySelector);
  const recieverEmail = useAppSelector(recieverEmailSelector);

  const getAccountData = useCallback(
    (key?: Key | null) =>
      accounts.find((account) => account.id === Number(key)),
    [accounts]
  );

  const isAllowToSend = useMemo(
    () =>
      getAccountData(senderEmailKey)?.verified &&
      !!senderEmailKey &&
      !!recieverEmail &&
      !!activeTemplateData,
    [activeTemplateData, getAccountData, recieverEmail, senderEmailKey]
  );

  const sendEmail = () => {
    if (emailStatus === EmailSendStatus.EMAIL_SENT) {
      window.parent.postMessage("closeldcmodal", "*");
    } else if (isAllowToSend) {
      sendAmplitudeData(AMPLITUDE_EVENTS.popupSendEmailClicked);
      dispatch(
        sendEmailAsync({
          toEmail: recieverEmail || "",
          account: getAccountData(senderEmailKey)?.email as string,
          tokens: { accessToken, refreshToken },
          subject: reformatText(
            activeTemplateData?.subject as string,
            emailData
          ),
          template: reformatText(
            activeTemplateData?.template as string,
            emailData
          ),
        })
      );
    }
  };

  const handleSubjectChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(setActiveTemplateData({ subject: e.target.value }));
  };

  const handleCKEditorChange = (value: string) => {
    sendAmplitudeData(AMPLITUDE_EVENTS.popupEmailBodyValueChanged);
    dispatch(setActiveTemplateData({ template: value }));
  };

  const handleSenderChange = useCallback(
    (key: Key | null) => {
      dispatch(setSenderEmailKey(key));
      sendAmplitudeData(AMPLITUDE_EVENTS.popupEmailSenderSwitched);
    },
    [dispatch]
  );

  const initialText = useMemo(
    () => reformatText(activeTemplateData?.template as string, emailData),
    [activeTemplateData?.template, emailData]
  );

  const initialSubject = useMemo(
    () => reformatText(activeTemplateData?.subject as string, emailData),
    [activeTemplateData?.subject, emailData]
  );

  useEffect(() => {
    if (accountData) dispatch(setSenderEmailKey(accountData.id));
  }, [accountData, dispatch]);

  const sendButtonIcon = useMemo(
    () =>
      emailStatus === EmailSendStatus.EMAIL_SENT ? <CloseIcon /> : <SendIcon />,
    [emailStatus]
  );

  const sendButtonTitle = useMemo(() => {
    switch (emailStatus) {
      case EmailSendStatus.NONE:
        return "Send";
      case EmailSendStatus.EMAIL_SENT:
        return "Email Successfully Sent";
      case EmailSendStatus.ERROR:
        return "Error";
      case EmailSendStatus.UNAUTHORIZED:
        return "Unauthorized";
      case EmailSendStatus.VERIFIFICATION_REQUIRED:
        return "Verification Required";
      case EmailSendStatus.RECHARGE:
        return "Recharge";
      default:
        return "Send";
    }
  }, [emailStatus]);

  return (
    <div className="flex flex-col gap-3 h-full">
      <AccountSelectField
        options={accounts}
        placeholder="Type email"
        label="Choose email sender"
        onSelectionChange={handleSenderChange}
        selectedKey={senderEmailKey?.toString()}
      />
      <AccountInputField
        placeholder="Type email"
        label="To"
        onValueChange={(value) => dispatch(setRecieverEmail(value))}
        value={recieverEmail}
      />
      <div className="border-1 rounded-lg border-gray-200 h-full flex">
        <TemplateSelector />
        <div className="w-full h-full flex flex-col">
          <input
            className="outline-none w-full h-11 border-b-1 border-gray-200 text-sm font-semibold p-3"
            type="text"
            value={initialSubject}
            onChange={handleSubjectChange}
          />
          <TextEditor onChange={handleCKEditorChange} value={initialText} />
        </div>
      </div>
      <div className="flex justify-end items-center gap-2">
        <StyledButton
          color="primary"
          endContent={sendButtonIcon}
          onClick={sendEmail}
          isDisabled={!isAllowToSend}
          isLoading={loading}
        >
          {sendButtonTitle}
        </StyledButton>
      </div>
    </div>
  );
};
