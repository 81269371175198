import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITripDataV2 } from "@/types/common";

interface ITripSliceState extends ITripDataV2 {
  distanceDifference: string;
  loading: boolean;
}

const initialState: ITripSliceState = {
  loading: false,
  distance: "",
  distanceDifference: "",
  duration: "",
  tollCost: "0",
  fuelPrice: {
    diesel: "",
  },
};

export const tripInfoSlice = createSlice({
  name: "tripInfo",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFuelPrice: (state, action: PayloadAction<{ diesel: string }>) => {
      state.fuelPrice = action.payload;
    },
    setTollCost: (state, action: PayloadAction<string>) => {
      state.tollCost = action.payload;
    },
    resetTollCost: (state) => {
      state.tollCost = "0";
    },
    setDistance: (state, action: PayloadAction<string>) => {
      state.distance = action.payload;
    },
    setDistanceDifference: (state, action: PayloadAction<string>) => {
      state.distanceDifference = action.payload;
    },
    setDuration: (state, action: PayloadAction<string>) => {
      state.duration = action.payload;
    },
    resetTripInfoState: () => initialState,
  },
});

export const {
  setFuelPrice,
  setTollCost,
  resetTollCost,
  resetTripInfoState,
  setDistance,
  setDuration,
  setDistanceDifference,
  setLoading,
} = tripInfoSlice.actions;

export default tripInfoSlice.reducer;
