import { Group } from "@/components/group";
import { IInspection } from "@/types/carrierReport";
import { useAppSelector } from "@/store/app/hooks";
import { carrierReportDataSelector } from "@/store/features/carrier-report/carrierReportSelectors";
import { ProgressCircle } from "./ProgressCircle";

export const Inspections = () => {
  const carrierReportData = useAppSelector(carrierReportDataSelector);
  const { united_states_inspections } = carrierReportData || {};
  const { driver, hazmat, iep, vehicle } = united_states_inspections || {};

  const getPercent = (arg?: IInspection) =>
    parseInt(arg?.out_of_service_percent?.replaceAll("%", "") || "");

  const getAveragePercent = (arg?: IInspection) => {
    if (arg?.national_average.includes("N/A")) return 100;
    return parseInt(arg?.national_average?.replaceAll("%", "") || "");
  };

  return (
    <Group className="shrink-0">
      <div className="grid grid-cols-4">
        <ProgressCircle
          label="Unsafe driving"
          value={getPercent(driver)}
          average={getAveragePercent(driver)}
        />
        <ProgressCircle
          label="HOS compliance"
          value={getPercent(hazmat)}
          average={getAveragePercent(hazmat)}
        />
        <ProgressCircle
          label="Crash indicator"
          value={getPercent(iep)}
          average={getAveragePercent(iep)}
        />
        <ProgressCircle
          label="Vehicle maintenance"
          value={getPercent(vehicle)}
          average={getAveragePercent(vehicle)}
        />
      </div>
    </Group>
  );
};
