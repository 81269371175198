import { FC } from "react";

interface IProps {
  color: string;
  value: string;
}

export const MapLegendItem: FC<IProps> = ({ color, value }) => {
  return (
    <div className="flex gap-2 items-center">
      <div className="w-3 h-3 rounded-md" style={{ background: color }}></div>
      <p className="text-xs">{value}</p>
    </div>
  );
};
