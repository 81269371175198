import { FC } from "react";
import { Circle } from "rc-progress";

interface IProps {
  label: string;
  value: number;
  average: number;
}

const COLORS = {
  success: "#17B26A",
  error: "#F04438",
  warning: "#FDB022",
  trail: "#E9EAEB",
};

export const ProgressCircle: FC<IProps> = ({ label, value, average }) => {
  const color =
    value > average
      ? COLORS.error
      : value > average / 2
      ? COLORS.warning
      : COLORS.success;

  return (
    <div className="flex flex-col gap-3 items-center">
      <h5 className="text-sm text-gray-500">{label}</h5>
      <div className="w-20 flex justify-center items-center relative">
        <Circle
          percent={value}
          strokeWidth={6}
          trailWidth={5}
          strokeColor={color}
          trailColor={COLORS.trail}
        />
        <div className="absolute text-sm font-medium">{value}%</div>
      </div>
    </div>
  );
};
